<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loading || loadingParent">
			<v-skeleton-loader type="card" />
		</template>
		<template v-else>
			<v-card class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>{{ $t('studies.studentStatus') }}</v-card-title>
				<v-card-text>
					<template v-if="user.faculties">
						<v-row>
							<v-col cols="12" lg="6" md="6" xs="12" v-for="studentProfile in user.studentProfiles" :key="studentProfile.id">
								<v-toolbar>
									<v-toolbar-title>{{ studentProfile.email }}</v-toolbar-title>
									<v-spacer />
									<template v-if="studentProfile.verified">
										<v-icon color="green">mdi-check</v-icon>
									</template>
									<template v-else>
										<v-icon color="red">mdi-close-circle-outline</v-icon>
									</template>
								</v-toolbar>

								<v-card v-for="degree in studentProfile.degrees" :key="degree.id" rounded="xl">
									<v-card-title>({{ degree.acronym }}) {{ degree.name }}</v-card-title>
									<v-card-text>
										<v-row v-for="course in degree.courses" :key="course.id">
											<v-col cols="12" md="5" sm="12">
												<span style="word-break: normal">{{ course.name }} </span>
											</v-col>
											<v-col cols="12" md="7" sm="12" class="d-flex justify-space-between">
												<span><v-icon>mdi-google-drive</v-icon> {{ course.sharedGDriveEmail }}</span>
												<div>
													<span v-for="(semester, index) in course.semesters" :key="semester.key">
														{{ semester.name }}{{ isLastItem(index, course.semesters) ? '' : '/' }}
													</span>
												</div>
												<v-btn icon color="red" @click="leaveCourse(course)">
													<v-icon>mdi-close</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</template>
					<template v-else>
						{{ $t('profile.noDegreesYet') }}
					</template>
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'UserStudentProfiles',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters({
			user: 'users/user'
		})
	},
	methods: {
		isLastItem(index, list) {
			return index + 1 === list.length
		},
		leaveCourse() {}
	}
}
</script>
